<template>
  <div class="content">
    <h1>lee.array.shuffle(Array)</h1>
    <p class="cm-variable">数组随机排序</p>
    <h3>参数</h3>
    <ul>
      <li><code>Array</code><em>(Array)</em>:排序的数组</li>
    </ul>
    <h3>例子</h3>
    <div class="jscode">
      <div class="jscontent" v-highlight>
        <pre><code class="language-javascript">let myArr = ['Html','Javascript','Css','Java','C#','C++','Php','Python','Vue','Sass']
let lastArr = lee.array.shuffle(myArr)
console.log(lastArr)
</code></pre>
      </div>
    </div>
    <div style="height: 15px;"></div>
    <div class="jscode" style="    border: solid 1px #dfe2e5;">
      <div class="jscontent">
        <transition-group name="list" tag="ul">
          <li v-for="item in list" :key="item.text">{{item.text}}</li>
        </transition-group>
        <button @click="shuffle">随机</button>
      </div>
    </div>
  </div>
</template>
<style>
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.jscontent ul {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.jscontent ul li {
  border: solid 1px #aaa;
  background: #f6f8fa;
  border-radius: 8px;
  margin-right: 5px;
  padding: 0 10px;
  box-shadow: inset 0 -3px 0 #aaa;
  transition: all 1s;
}

</style>
<script>
export default {
    components: {},
    data() {
        return {
            list: []
        }
    },
    created() {
        let myArr=[
            {text:'Html'},{text:'Javascript'},{text:'Css'},{text:'Java'},{text:'C#'},{text:'C++'},{text:'Php'},{text:'Python'},{text:'Vue'},{text:'Sass'}
            ]
        //let myArr = ['Html', 'Javascript', 'Css', 'Java', 'C#', 'C++', 'Php', 'Python', 'Vue', 'Sass']
        this.list = myArr
    },
    methods: {
        shuffle() {
            let lee = window.lee
            let lastArr = lee.array.shuffle(this.list)
            this.list = []
            this.list = lastArr
        }
    },
    computed: {}
}
</script>
